<template>
	<router-view :key="$route.params.id"/>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">				
				<h4 class="p-m-2">Reportes</h4>
				<h5 class="p-m-2">{{ nombreGrupoAcceso }}</h5>				
				<Toast/>			
			</div>
			<DataTable ref="dt" :value="reportes" class="p-datatable-customers" selectionMode="single" :rowClass="rowClass" @row-click="mostrarInfoRpt" dataKey="id_app_opcion" :paginator="true" :rows="10" :filters="filters"
						:globalFilterFields="['titulo']" 
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
						currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loadingReports">
				<template #header>
					<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">							
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters['global'].value" placeholder="Buscar..." />
						</span>
					</div>
				</template>	
				<Column field="titulo" header="Titulo" :sortable="true">
					<template #body="slotProps">											
							<i v-if="slotProps.data.tipo=='RP'" class="pi pi-file-pdf" style="font-size: 1rem; color: red"></i>
							<i v-if="slotProps.data.tipo=='EX'" class="pi pi-file-excel" style="font-size: 1rem; color: green"></i>
							{{ slotProps.data.titulo }}
					</template>
				</Column>							
				<Column>
					<template #body="slotProps">							
						<Button text="Imprimir" icon="pi pi-search" class="p-button-rounded p-button-primary p-mr-2" @click="cargarInfoRpt(slotProps.data)" />											
					</template>
				</Column>
			</DataTable>		
		
		<BuscarCtaHabiente :key="dlgBuscarCtaHabienteRefresh" :mostrar="dlgBuscarCtaHabiente" @cancelar="cerrarDlgCtahabiente" @seleccionar="selCtaHabiente"/>
		<MdsBuscarInmueble :key="dlgBuscarInmuebleRefresh" :mostrar="dlgInmuebleDialog" @cancelar="cerrarDlgInmueble" @seleccionar="selInnmueble"/>

		<BuscarProducto ref="dlgBuscaProducto" :mostrar="dlgProducto" @cancelar="cerrarDlgProducto"  @seleccionar="elegirProducto" :id_lista="selCtaHabiente.id_producto_lista" :filtro="filtroProducto" :key="dlgBuscarProductoRefresh"/>
		<Dialog v-model:visible="reporteDlg" :style="{width: '1150px', height: '600px'}"  :showHeader="true" :modal="true" >
			<template #header>
				<div class="p-fluid">											
					<div class="p-fluid p-m-1">
						<h4 >
						{{ reporteSeleccionado.titulo}}
						</h4>				
					</div>				
				</div>
			</template>			
			<div :style="{height: '425px'}">		

			<Panel :key="filtrosUpdate" header="Filtros" class="p-mb-2">
				<template #icons>
					<i class="pi pi-sliders-v"></i>
				</template>
				<div v-if="existeParam('idSucursal')" class="p-fluid p-formgrid p-grid">				
					<div class="p-field p-m-1">
						<label for="sucursal" class="p-text-regular">Sucursal</label>																																					
						<Dropdown v-model="idSucursal" :options="lkpSucursales" optionLabel="sucursal" optionValue="id_sucursal" placeholder="Sucursal..."/>						
					</div>					
					<div v-if="existeParam('idBodega')"  class="p-field p-m-1">
						<label for="bodega" class="p-text-regular">Bodega</label>																																					
						<Dropdown v-model="idBodega" :options="lkpBodegas" optionLabel="bodega" optionValue="id_bodega" placeholder="Bodega..."/>						
					</div>					
				</div>		

				<div v-if="existeParam('idMoneda')"  class="p-fluid p-formgrid p-grid">
					<div class="p-field p-m-1">												
						<label for="moneda" class="p-text-regular">Moneda</label>
						<Dropdown v-model="idMoneda" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" placeholder="Moneda..."/>						
					</div>
				</div>

				<div  v-if="existeParam('FechaIni')&&existeParam('FechaFin')"  class="p-fluid p-formgrid p-grid">
					<div class="p-field p-m-1">
						<label for="Periodo" class="p-text-regular">Período</label>
						<SeleccionFecha @nuevoPeriodo="actualizarPeriodo"/>
					</div>
				</div>
				<div  v-if="existeParam('idPeriodo')"  class="p-fluid p-formgrid p-grid">
					<div class="p-field p-m-1">
						<label for="Periodo" class="p-text-regular">Período</label>
						<Calendar v-model="periodo" view="month" selectionMode="single" dateFormat="mm/yy" @date-select="selPeriodo" :manualInput="false"/>
						<!-- <Calendar v-model="periodo" selectionMode="single" dateFormat="mm/yy" @blur="selPeriodo" :manualInput="false"/> -->
					</div>
				</div>
				<div v-if="existeParam('FechaIni')" class="p-fluid p-formgrid p-grid">
					<div class="p-field p-m-1">
						<label for="desde" class="p-text-regular">Desde</label>
						<Calendar id="desde" v-model="FechaIni" :key="periodoUpdate"/>
					</div>
				</div>		
				<div v-if="existeParam('FechaFin')" class="p-fluid p-formgrid p-grid">
					<div class="p-field p-m-1">
						<label for="hasta" class="p-text-regular">Hasta</label>
						<Calendar id="hasta" v-model="FechaFin" :key="periodoUpdate"/>
					</div>
				</div>			
				<div v-if="existeParam('idCtaHabienteInfo')"  class="p-fluid p-formgrid p-grid">
					<div class="p-field p-m-1">
						<label for="cuentahabiente" class="p-text-regular">Cuentahabiente</label>
						<div class="p-fluid p-inputgroup">
						<InputText size="135" id="cuentahabiente" type="text" v-model="CtahabienteNombre" disabled/>																												
						<Button v-if="mostrarBotonBusquedaCtaHabiente" icon="pi pi-search" @click="buscarCtahabiente"/>							
						<Button v-if="!mostrarBotonBusquedaCtaHabiente" icon="pi pi-times" class="p-button-warning" @click="buscarCtahabiente"/>							
						</div>
					</div>
				</div>
				<div v-if="existeParam('idInmueble')"  class="p-fluid p-formgrid p-grid">
					<div class="p-field p-m-1">
						<label for="inmueble" class="p-text-regular">Inmueble</label>
						<div class="p-fluid p-inputgroup">
						<InputText size="135" id="inmueble" type="text" v-model="InmuebleNombre" disabled/>																												
						<Button v-if="mostrarBotonBusquedaInmueble" icon="pi pi-search" @click="buscarInmueble"/>							
						<Button v-if="!mostrarBotonBusquedaInmueble" icon="pi pi-times" class="p-button-warning" @click="buscarInmueble"/>							
						</div>
					</div>
				</div>					
				<div v-if="existeParam('idProductoEmpresa')"  class="p-fluid p-formgrid p-grid">
					<div class="p-field p-m-1">												
						<label for="producto" class="p-text-regular">Producto</label>
						<div class="p-fluid p-inputgroup">
							<InputText size="100" id="codigo" placeholder="(Todos)" type="text" v-model="productoDisplay" disabled/>				
							<Button v-if="productoDisplay==''" icon="pi pi-search" @click="seleccionarProducto"/>							
							<Button v-if="!productoDisplay==''" icon="pi pi-times" class="p-button-warning" @click="productoDisplay=''"/>							
						</div>
					</div>
				</div>
				<div v-if="existeParam('idCategoria')"  class="p-fluid p-formgrid p-grid">
					<div class="p-field p-m-1">
						<label for="Categoria" class="p-text-regular">Categoría</label>
						<Dropdown v-model="idCategoria" :options="lkpCategorias" optionLabel="categoria" optionValue="id_categoria"/>
					</div>
				</div>		
				<div v-if="existeParam('idClasificacion')"  class="p-fluid p-formgrid p-grid">
					<div class="p-field p-m-1">
						<label for="Clasificacion" class="p-text-regular">Clasificación</label>
						<Dropdown v-model="idClasificacion" :options="lkpClasificaciones" optionLabel="clasificacion" optionValue="id_clasificacion"/>
					</div>
				</div>
				<div v-if="existeParam('idSubclasificacion')"  class="p-fluid p-formgrid p-grid">
					<div class="p-field p-m-1">
						<label for="Subclasificacion" class="p-text-regular">Subclasificación</label>
						<Dropdown v-model="idSubclasificacion" :options="lkpSubclasificaciones" optionLabel="subclasificacion" optionValue="id_subclasificacion"/>
					</div>
				</div>
				<div v-if="existeParam('idMarca')"  class="p-fluid p-formgrid p-grid">
					<div class="p-field p-m-1">
						<label for="Marca" class="p-text-regular">Marca</label>
						<Dropdown v-model="idMarca" :options="lkpMarcas" optionLabel="marca" optionValue="id_marca"/>
					</div>
				</div>
			</Panel>		
			</div>
			<template #footer>
				<div class="p-field p-col">
					<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="reporteDlg=false"/>
					<Button label="Generar" icon="pi pi-cog" class="p-button-text" @click="generar"/>
				</div>
			</template>
		</Dialog>
		</div>
	</div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import SeleccionFecha from '../components/SeleccionFecha.vue';
import BuscarCtaHabiente from '../components/BuscarCtaHabiente.vue';
import MdsBuscarInmueble from '../components/MdsBuscarInmueble.vue';
import BuscarProducto from '../components/BuscarProducto.vue';
import GrlService from '../service/grl.service';
import InvService from '../service/inv.service';
import UserService from '../service/user.service';
import { CURRENT_APP_MODE } from '../service/constants';
import { read, utils, writeFileXLSX } from 'xlsx';

export default {
	setup() {
		const rowClass = (data) => {
            return data.anulado === 'S' ? 'row-anulada': null;
        };

		return { rowClass }
	},	
    components: {
        Loading, SeleccionFecha, BuscarCtaHabiente, MdsBuscarInmueble, BuscarProducto	
    },
	data() {
		return {
            appMode: CURRENT_APP_MODE,
			ctaHabienteTitulo: CURRENT_APP_MODE==0?'Cuentahabiente':'Inmueble',
			reporteDlg: false,
			reporteSeleccionado: {
				titulo: "Libro de Ventas"
			},
			idGrupoAcceso: this.id,
			nombreGrupoAcceso: null,
			lkpSucursales: null,
			lkpBodegas: null,
			lkpMonedas: null,			
			CtahabienteNombre: '(Todos)',
			InmuebleNombre: '(Todos)',
			dlgBuscarCtaHabiente : false,
			dlgBuscarCtaHabienteRefresh: 0,
			dlgInmuebleDialog: false,
			dlgProducto: false,
			reportes: null,
			idEmpresa: null,
			idSucursal: null,
			idBodega: null,
			idMoneda: null,
			dlgBuscarProductoRefresh: 0,
			dlgBuscarInmuebleRefresh: 0,
			filtroProducto: '',			
			idCtaHabienteInfo: null,
			idInmueble: null,
			FechaIni: new Date(),
			FechaFin: new Date(),
			periodo: new Date(),
			idPeriodo: null,
			idProductoEmpresa: null,
			productoDisplay: '',
			idMarca: null,
			idCategoria: "0",
			idClasificacion: "0",
			idSubclasificacion: null,
			loading: false,
			loadingReports: false,
			paramsMap: new Map(),
			filtrosUpdate: 0,
			periodoUpdate: 0,
			lkpCategorias: null,
			lkpClasificaciones: null,
			lkpSubclasificaciones: null,
			lkpMarcas: null,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},			
			submitted: false
		}
	},
	props: {
		id: {
			type: String,
			required: true
		}
	},		
	mounted () {
		this.lkpSucursales = this.$store.state.auth.currentAppCtx.Sucursales.slice();
		this.lkpSucursales.splice(0, 0, {id_sucursal: '0', sucursal: "(Todas)"});	
		
		this.lkpBodegas = this.$store.state.auth.currentAppCtx.Bodegas.slice();
		this.lkpBodegas.splice(0, 0, {id_bodega: '0', bodega: '(Todas)'});

		this.lkpMonedas = this.$store.state.auth.currentAppCtx.Monedas;

		this.paramsMap.clear();

		this.cargarListado();
	},
	watch: {
		id()
		{
			this.cargarListado();	
		}
	},	
	computed: {
		mostrarBotonBusquedaCtaHabiente(){
			return (this.idCtaHabienteInfo == '0')
		},
		mostrarBotonBusquedaInmueble(){
			return (this.idInmueble == '0')
		},		
	},
	methods: {
		cargarListado() {
			this.loadingReports = true;

			UserService.getGrupoAccesoXId(this.$store.state.auth.currentAppCtx, this.id).then(data => {
					this.loadingReports = false;

					if (data.length > 0) {
						this.nombreGrupoAcceso = data[0].grupo;

						GrlService.GetRptsXGrupo(this.$store.state.auth.currentAppCtx, this.id).then(data => {
							this.loadingReports = false;
							this.reportes = data;
						}).catch(err => {
							this.loadingReports = false;
							this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
						}
						);	
					}
				}
			).catch(err => {
							this.loadingReports = false;
							this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
						}
						);	
		},
		resetFiltros()
		{
			this.idEmpresa = this.$store.state.auth.currentAppCtx.id_referencia;
			this.idSucursal = "0";
			this.idBodega = "0";
			this.idMoneda = this.$store.state.auth.currentAppCtx.monedaBase.id_moneda;
			this.idCtaHabienteInfo = "0";
			this.idInmueble = "0";
			this.idProductoEmpresa = "0";
			this.productoDisplay = "";
			this.idMarca = "0";
			this.idCategoria = "0";
			this.idClasificacion  = "0";
			this.idSubclasificacion = "0";
			this.CtahabienteNombre = "(Todos)";
			this.InmuebleNombre = "(Todos)";
			this.fechaIni = new Date();
			this.fechaFin = new Date();
			this.periodo = new Date();
			this.idPeriodo = null;
			this.paramsMap.clear();
		},		
		async cargarValoresDefault()
		{
			if(this.existeParam('idPeriodo')){
				this.periodo = new Date();
				await this.selPeriodo();
			}
		},
		buscarProducto()
		{
			// AppCtx, filtro, mostrarConversiones, tipoProducto, idLista, mostrarMateriaPrima
			this.productoDialogLoading = true;
			InvService.buscaProductos(this.$store.state.auth.currentAppCtx, this.filtroProducto, 'S', '', '', 'S').then(data => 
			{
				if(data.length == 1){
					data[0].mostrar = 'S';
					this.elegirProducto(data[0]);	
				} else {							
					this.$refs.dlgBuscaProducto.mostrarResultadosBusqueda(this.filtroProducto, data);							
				}
				this.productoDialogLoading = false;
			}	
			).catch(
				data => {
				this.productoDialogLoading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}				
			);				
		},
		seleccionarProducto(){
			if(this.filtroProducto == '') {
				this.dlgProducto = true;
				this.dlgBuscarProductoRefresh++;
			} else {
				this.buscarProducto();
			}
		},
		cerrarDlgProducto(){
			this.filtroProducto = '';
			this.dlgProducto = false;
		},
		elegirProducto(Producto){
			this.dlgProducto = false;	
			this.filtroProducto = '';
			
			this.idProductoEmpresa = Producto.id_producto_empresa;	
			this.productoDisplay = Producto.producto;		;
		},
		actualizarPeriodo(periodo)
		{
			this.FechaIni = periodo.desde;
			this.FechaFin = periodo.hasta;
			this.periodoUpdate += 1;
		},
		existeParam(paramName){
			var existe = this.paramsMap.has(paramName);		
			return existe;
		},
		mostrarInfoRpt(e){			
			this.cargarInfoRpt(e.data);
		},
		async cargarInfoRpt(e) {
			this.loading = true;		
			try {
				let data;

				if(e.tipo == 'RP') {
					data = await GrlService.GetRptInfo(this.$store.state.auth.currentAppCtx, e.id_app_opcion);
				} else if(e.tipo == 'EX') {
					data = await GrlService.GetExportInfo(this.$store.state.auth.currentAppCtx, e.id_app_opcion);
				} else {
					exit;
				}

				this.resetFiltros();
				this.reporteSeleccionado = data;
				let param = null;
				for(var i=0; i < this.reporteSeleccionado.params.length; i++){
					param = this.reporteSeleccionado.params[i];
					this.paramsMap.set(param.ParamName, param.Valor);
				}
				
				if(this.paramsMap.has('idCategoria')){
					this.lkpCategorias = await InvService.getCategoriasProducto(this.$store.state.auth.currentAppCtx);	
					this.lkpCategorias.unshift({id_categoria: "0", categoria: "(Todas)"});
				}	

				if(this.paramsMap.has('idClasificacion')){
					this.lkpClasificaciones = await InvService.getClasificaciones(this.$store.state.auth.currentAppCtx);	
					this.lkpClasificaciones.unshift({id_clasificacion: "0", clasificacion: "(Todas)"});
				}	

				if(this.paramsMap.has('idSubclasificacion')){
					this.lkpSubclasificaciones = await InvService.getSubclasificaciones(this.$store.state.auth.currentAppCtx);	
					this.lkpSubclasificaciones.unshift({id_subclasificacion: "0", subclasificacion: "(Todas)"});
				}	
				
				if(this.paramsMap.has('idMarca')){
					this.lkpMarcas = await InvService.getProductosMarcas(this.$store.state.auth.currentAppCtx);	
					this.lkpMarcas.unshift({id_marca: "0", marca: "(Todas)"});
				}	

				this.cargarValoresDefault();

				this.filtrosUpdate++;
				this.reporteDlg = true;
				this.loading = false;					
			} catch(data) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}		
		},
		buscarCtahabiente() {
			if(this.idCtaHabienteInfo == '0'){								
				this.dlgBuscarCtaHabiente = true;
				this.dlgBuscarCtaHabienteRefresh++;
			} else {
				this.CtahabienteNombre = '(Todos)';
				this.idCtaHabienteInfo = '0';		
			}
		},
		selCtaHabiente(CtaHabiente)
		{
			this.idCtaHabienteInfo= CtaHabiente.id_cta_habiente_info;			
			this.CtahabienteNombre = CtaHabiente.nombre_comercial;
			this.dlgBuscarCtaHabiente = false;
		},
		buscarInmueble() {
			if(this.idInmueble == '0'){								
				this.dlgInmuebleDialog = true;
				this.dlgBuscarInmuebleRefresh++;
			} else {
				this.InmuebleNombre = '(Todos)';
				this.idInmueble = '0';		
			}
		},
		selInnmueble(Inmueble) {
			console.log(`Inmueble: ${JSON.stringify(Inmueble)}`);
			this.idInmueble= Inmueble.id_inmueble;			
			this.InmuebleNombre = Inmueble.display;
			this.dlgInmuebleDialog = false;
		},
		async selPeriodo() {
			try {
				var data = await GrlService.GetPeriodoXFecha(this.$store.state.auth.currentAppCtx, this.periodo);
				this.idPeriodo = data.id_periodo;
			} catch (error) {
				console.log(error);
			}
		},
		cerrarDlgCtahabiente() {
			this.dlgBuscarCtaHabiente = false;
		},	
		cerrarDlgInmueble(){
			this.dlgInmuebleDialog = false;
		},						
		getParamsValues(){

			if(this.paramsMap.has('idEmpresa')){
				this.paramsMap.set('idEmpresa', this.idEmpresa);
			}

			if(this.paramsMap.has('idSucursal')){
				this.paramsMap.set('idSucursal', this.idSucursal);
			}

			if(this.paramsMap.has('idBodega')){
				this.paramsMap.set('idBodega', this.idBodega);
			}

			if(this.paramsMap.has('idMoneda')){
				this.paramsMap.set('idMoneda', this.idMoneda);
			}

			if(this.paramsMap.has('idCtaHabienteInfo')){
				this.paramsMap.set('idCtaHabienteInfo', this.idCtaHabienteInfo);
			}
			
			if(this.paramsMap.has('idInmueble')){
				this.paramsMap.set('idInmueble', this.idInmueble);
			}

			if(this.paramsMap.has('idProductoEmpresa')){
				this.paramsMap.set('idProductoEmpresa', this.idProductoEmpresa);
			}

			if(this.paramsMap.has('idCategoria')){
				this.paramsMap.set('idCategoria', this.idCategoria);
			}

			if(this.paramsMap.has('idClasificacion')){
				this.paramsMap.set('idClasificacion', this.idClasificacion);
			}

			if(this.paramsMap.has('idSubclasificacion')){
				this.paramsMap.set('idSubclasificacion', this.idSubclasificacion);
			}

			if(this.paramsMap.has('idMarca')){
				this.paramsMap.set('idMarca', this.idMarca);
			}

			if(this.paramsMap.has('FechaIni')){
				this.paramsMap.set('FechaIni', this.FechaIni);
			}

			if(this.paramsMap.has('FechaFin')){
				this.paramsMap.set('FechaFin', this.FechaFin);
			}

			if(this.paramsMap.has('idPeriodo')){
				this.paramsMap.set('idPeriodo', this.idPeriodo.toString());
			}

			var params = Array.from(this.paramsMap, ([name, value]) => ({ "ParamName": name, "Valor": value }));
			return params;
		},		
		async generar(){			
			
			try {
				let Data = {
					reporte: `${this.reporteSeleccionado.reporte}`,
					titulo: '',
					tipo: '',
					params: this.getParamsValues()
				};

				this.loading = true;
				let response;
				if(this.reporteSeleccionado.tipo == 'RP') {
					response = await GrlService.GenerarRpt(this.$store.state.auth.currentAppCtx, Data);
					this.reporteDlg = false;
					this.loading = false;	
					
					const file = new Blob(
					[response.data], 
					{type: 'application/pdf'});
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);	

					var link = document.createElement('a');
					link.href = fileURL;
					link.download = `${this.reporteSeleccionado.titulo}.pdf`;
					link.dispatchEvent(new MouseEvent('click'));
				} else if (this.reporteSeleccionado.tipo == 'EX') {
					response = await GrlService.GenerarExport(this.$store.state.auth.currentAppCtx, Data);
					
					this.reporteDlg = false;
					this.loading = false;	

					const ws = utils.json_to_sheet(response.data);
					var wb = utils.book_new();
					utils.book_append_sheet(wb, ws, `${this.reporteSeleccionado.titulo}`);
					writeFileXLSX(wb, `${this.reporteSeleccionado.titulo}.xlsx`);					
				}
			} catch(err) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})					
			}

		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}

::v-deep(.row-anulada) {
    background-color: rgba(0,0,0,.15) !important;
}

.vencido {
    font-weight: 700;
    color: red;
}

</style>    